import constants from '@/constants'
import { RouteConfig } from 'vue-router'
import store from '@/store'
import {
  ACTION_CLAIM_LOAD_REPAIRS
} from '@/store/modules/claim/actionTypes'

export const claimsRoutes: Array<RouteConfig> = [
  {
    path: '/auth/viewClaim',
    // name: 'ViewClaim',
    component: () =>
      import(/* webpackChunkName: "viewClaim" */ '@/views/auth/ViewClaim/Index.vue'),
    beforeEnter(to, from, next) {
      store.dispatch(ACTION_CLAIM_LOAD_REPAIRS)
        .then(next)
        .catch(e => {
          console.error(e)
          next({ name: constants.routeNames.ERROR })
        })
    },
    meta: {
      pageIndex: 1
    },
    children: [
      {
        path: '',
        name: constants.routeNames.VIEW_CLAIM_LIST,
        component: () =>
          import(
            /* webpackChunkName: "viewClaim" */ '@/views/auth/ViewClaim/ViewClaimList.vue'
          ),
        meta: {
          pageIndex: 1.1
        }
      },
      {
        path: ':id',
        name: constants.routeNames.VIEW_CLAIM_DETAIL,
        component: () =>
          import(
            /* webpackChunkName: "viewClaim" */ '@/views/auth/ViewClaim/ViewClaimDetail.vue'
          ),
        meta: {
          pageIndex: 1.2
        }
      }
    ]
  }
]
