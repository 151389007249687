import {
  Product,
  ClaimConfig,
  Claim
} from '@uncharted/coverhub-framework'
import { Config } from '@/config'
import { Claims } from './api/Claims'
import { ClaimDefinitionModel } from 'shift-claim-service-api-client'
import { ProductModel } from 'shift-product-service-api-client'
import { PolicyModel } from 'shift-policy-service-api-client'

export const ClaimsService = {
  /**
   * Returns a claim, properly initialized
   * @param claimId Claim id
   * @param claimDefinition
   * @param productModel
   * @param policyModel
   */
  async getClaimById(claimId: string, claimDefinition: ClaimDefinitionModel, productModel: ProductModel, policyModel: PolicyModel): Promise<Claim> {
    // Load the claim
    const claimModel = (await Claims.api.getClaim(claimId)).data.data
    // Create a product with product engine
    const product = new Product({
      definition: productModel.definition,
      serialized: policyModel.config,
      language: Config.locale
    })
    // Define a filter for filtering accepted claims
    // for the given policy, excluding this claim
    const claimFilter = `claimDefinitionId[eq]=${claimDefinition.id}|id[!eq]=${claimId}|policyId[eq]=${claimModel.policyId}|status[eq]=ACCEPTED`
    // Load accepted claims during coverage period
    const prevClaimModels = (
      await Claims.api.getAllClaims(
        undefined,
        undefined,
        undefined,
        claimFilter
      )
    ).data.data
    // Extract claim config from previous claims
    const prevClaimConfigs = prevClaimModels.map((cm) => cm.config)
    // Define config for a claim, that wraps all this stuff
    const claimConfig: ClaimConfig = {
      language: Config.locale,
      definition: claimDefinition.definition,
      product: product,
      previousClaims: prevClaimConfigs,
      serialized: claimModel.config,
      meta: {
        claimDefinitionId: claimDefinition.id,
        policyId: policyModel.id
      }
    }
    // Now, create the new empty claim and return it
    return new Claim(claimModel.claimNumber, claimConfig)
  }
}
