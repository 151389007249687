import { Shift } from '@/services/Shift'
import { ClaimState } from '@/store/api/ClaimState'
import { RootState } from '@/store/api/RootState'
import { ActionContext, ActionTree } from 'vuex'
import {
  SET_CLAIM_VALIDATE,
  SET_REPAIRS,
  SET_FACTS
} from './mutationTypes'

type ClaimContext = ActionContext<ClaimState, RootState>

export const actions: ActionTree<ClaimState, RootState> = {
  describeFacts({ commit, state }: ClaimContext, overlayName: string) {
    const overlay = state.overlays.find(o => o.name === overlayName)
    if (overlay) {
      const facts = overlay.describeFacts().facts
      commit(SET_FACTS, facts)
      commit(SET_CLAIM_VALIDATE, overlayName)
    }
  },
  async loadRepairs({ commit }: ClaimContext) {
    const claims = await Shift.loadRepairs()
    commit(SET_REPAIRS, claims)
  }
}
