import { Config } from '@/config'
import { ProductModel } from 'shift-product-service-api-client'
import { Products } from '@/services/api/Products'
import { Workflows } from '@/services/api/Workflows'
import { Policies } from '@/services/api/Policies'
import { createClaimOverlay, RepairModel } from '@/services/api/models/RepairModel'
import { RunWorkflowProcessRequestModel } from 'shift-workflow-service-api-client'
import {
  ConvertQuoteModel, ConvertQuoteResponseModel, InvoiceModel, InvoiceSettlementStatusResponseModel, PolicyBundleModel, PolicyModel, PolicyPartyModel
} from 'shift-policy-service-api-client'
import { Cms } from './api/Cms'
import { ContentObjectModel } from 'shift-content-mgmt-service-api-client'
import { keepOnErrorAxios } from '@/services/Auth'
import { SwitchPolicyModel } from './api/models/SwitchPolicyModel'
import moment from 'moment'
import { ProposalWithQuotesCreateModel } from 'shift-quote-service-api-client'
import { Quotes } from './api/Quotes'
import { Claims } from './api/Claims'
import { ClaimDefinitionModel, ClaimModel } from 'shift-claim-service-api-client'
import { Claim, ClaimConfig, Product, shift } from '@uncharted/coverhub-framework'
import { ClaimsService } from './ClaimsService'
import { PdfPolicyModel } from './api/models/PdfPolicyModel'
import { Photo } from '@/store/api/models/PhotoModel'
import RecaptchaModel from '@/services/RecaptchaModel'
import newSessionFromConfig = shift.productengine.newSessionFromConfig;
import store from '@/store'
import { ACTION_SET_BUNDLE, ACTION_SET_SALE_JOURNEY_STATE_POLICY_CREATED, ACTION_SET_SALE_JOURNEY_STATE_QUOTE_CREATED } from '@/store/modules/product/actionTypes'
import { ACTION_POLICY_SET_CONVERTED_POLICY } from '@/store/modules/policy/actionTypes'

const cachedProducts: any = {}
const cachedClaimDefinitions: any = {}
const POLICY_STAND_DOWN_MONTH = 1

function policyInStandDown(session: any, originalStartDateTime: string): boolean {
  const newDevice = session.getFactValue('COMPONENT:base.plan.newdevice')
  const underManufacturerWarranty = session.getFactValue('COMPONENT:base.plan.manufacturerwarranty')

  if (newDevice === 'true' && underManufacturerWarranty === 'true') {
    return false
  }

  const coverageStartDateUTC = moment.utc(originalStartDateTime)
  return moment.utc().diff(coverageStartDateUTC, 'month') < POLICY_STAND_DOWN_MONTH
}

function getRepairEligibilityStartDate(originalStartDateTime: string): string {
  return moment(originalStartDateTime).add(POLICY_STAND_DOWN_MONTH, 'months').add(1, 'days').utc().format('yyyy-MM-DD HH:mm:ss')
}

export const Shift = {
  async getProduct(): Promise<ProductModel> {
    const response = await Products.api.getAvailableProducts()
    if (response.data.data.length > 0) {
      return response.data.data[0]
    } else {
      throw new Error('Product not found')
    }
  },
  async getInvoice(uuid: string): Promise<InvoiceModel | null> {
    return (await Policies.api.getInvoice(uuid)).data.data
  },
  async getLatestInvoice(): Promise<InvoiceModel | null> {
    const response = (await Policies.api.getAllInvoices(0, 1, '-sfCreated')).data.data
    return response.length === 1 ? response[0] : null
  },
  // List policies of logged in user
  async getPolicies(onlyInForcePolicies = false): Promise<SwitchPolicyModel[]> {
    const filter = onlyInForcePolicies ? 'status[in]=IN_FORCE,EXPIRING,LAPSING' : 'status[in]=IN_FORCE,EXPIRING,EXPIRED,LAPSING,LAPSED,AUTO_LAPSED'
    const policies = (await Policies.api.getAllPolicies(undefined, 1000, '-policyNumber', filter)).data.data

    const models: SwitchPolicyModel[] = []

    function getBenefitResetStartDate(benefitResetDate: moment.Moment): moment.Moment {
      if (moment().isBefore(moment(benefitResetDate).add(1, 'year'))) {
        return benefitResetDate
      }
      return getBenefitResetStartDate(benefitResetDate.add(1, 'year'))
    }

    for (const policy of policies) {
      const product = await this.getProductById(policy.productId)
      const session = newSessionFromConfig(product.definition, policy.config)
      session.setLanguage(Config.locale)

      const claimDefinition = await Shift.getClaimDefinition(product.id)
      const filterWarranty = `claimDefinitionId[eq]=${claimDefinition.id}|policyId[eq]=${policy.id}|status[eq]=ACCEPTED`
      // sort by latest claim
      const lastestClaim = (await Claims.api.getAllClaims(0, 1, '-sfCreated', filterWarranty)).data.data

      let repairTimesLastOneYear = '0'
      let amortizeRepairCost = '0'

      if (lastestClaim.length > 0) {
        const claim = await ClaimsService.getClaimById(lastestClaim[0].id, claimDefinition, product, policy)
        const claimEngine = claim.claimEngine

        // only set if the latest claim is for the current period
        const benefitResetStartDate = getBenefitResetStartDate(moment(policy.originalStartDateTime))
        if (moment(claimEngine.getFactValue('#claimStart')).isSameOrAfter(benefitResetStartDate)) {
          const outcomes = claimEngine.describeOutcomeFacts()

          const allRepairsFact = outcomes.facts.find(fact => fact.id === 'outcome.claims.allRepairs')

          if (allRepairsFact) {
            repairTimesLastOneYear = allRepairsFact.currentValue
          }

          const allClaimTotalsFact = outcomes.facts.find(fact => fact.id === 'outcome.claims.allClaimTotals')

          if (allClaimTotalsFact) {
            amortizeRepairCost = allClaimTotalsFact.currentValue
          }
        }
      }

      models.push({
        policyId: policy.id,
        productId: product.id,
        fullNameKana: session.getFactValue('applicant.firstname') + session.getFactValue('applicant.lastname'),
        email: session.getFactValue('applicant.email'),
        deviceType: session.getFactValue('COMPONENT:base.coverage.devicetype'),
        deviceNickname: session.getFactValue('COMPONENT:base.deviceregistration.nickname'),
        serialNumber: session.getFactValue('COMPONENT:base.deviceregistration.photofront.serialbody'),
        productionNumberJoyConLeft: session.getFactValue('COMPONENT:base.deviceregistration.photofront.serialjoyconleft'),
        productionNumberJoyConRight: session.getFactValue('COMPONENT:base.deviceregistration.photofront.serialjoyconright'),
        photoFront: session.getFactValue('COMPONENT:base.deviceregistration.photofront'),
        photoBack: session.getFactValue('COMPONENT:base.deviceregistration.photoback'),
        coverageStartDate: policy.coverageStartDate,
        coverageEndDate: policy.coverageEndDate,
        originalStartDateTime: policy.originalStartDateTime,
        repairTimesLastOneYear: repairTimesLastOneYear,
        amortizeRepairCost: amortizeRepairCost,
        planType: session.getFactValue('COMPONENT:base.plan.plan'),
        inStandDown: policyInStandDown(session, policy.originalStartDateTime),
        repairEligibilityStartDate: getRepairEligibilityStartDate(policy.originalStartDateTime),
        hasCurrentClaim: await Shift.hasCurrentClaim(policy, product, claimDefinition),
        _product: new Product({
          definition: product.definition,
          serialized: policy.config,
          language: Config.locale
        }),
        _policy: policy,
        expired: policy.status === 'EXPIRED' || policy.status === 'LAPSED' || policy.status === 'AUTO_LAPSED'
      })
    }
    return models
  },
  async hasCurrentClaim(policy: PolicyModel, product: ProductModel, claimDefinition: ClaimDefinitionModel): Promise<boolean> {
    const filterWarranty = `claimDefinitionId[eq]=${claimDefinition.id}|policyId[eq]=${policy.id}|status[in]=NEW,PROCESSING`
    const claimModels = (await Claims.api.getAllClaims(0, 1000, '-sfCreated', filterWarranty)).data.data
    for (const claim of claimModels) {
      const hydratedClaim = await ClaimsService.getClaimById(claim.id, claimDefinition, product, policy)
      const status = hydratedClaim.claimEngine.getFactValue('outcome.customRepairStatus')
      const hasCurrent = [null, '', '01', '03', '04', '05', '07', '08'].includes(status)
      if (hasCurrent) {
        return true
      }
    }
    return false
  },
  // Get a specific product for a user
  async getProductById(id: string): Promise<ProductModel> {
    let product: ProductModel = cachedProducts[id]
    if (!product) {
      product = (await Products.api.getProduct(id)).data.data
      cachedProducts[product.id] = product
    }
    return product
  },
  async convertQuotes(convertQuote: ConvertQuoteModel): Promise<ConvertQuoteResponseModel> {
    const convertResp = await Policies.api.convertQuotes(convertQuote)
    await store.dispatch(ACTION_POLICY_SET_CONVERTED_POLICY, convertResp.data.data.policies[0])
    await store.dispatch(ACTION_SET_BUNDLE, convertResp.data.data.bundle)
    await store.dispatch(ACTION_SET_SALE_JOURNEY_STATE_POLICY_CREATED, true)
    return convertResp.data.data
  },
  async createProposalWithQuotes(productConfig: string, productId: string): Promise<ConvertQuoteModel> {
    const newQuote: any = { productConfig, productId }
    const newProposal: ProposalWithQuotesCreateModel = {
      quotes: [newQuote] // only single quote for now
    }
    const proposalResp = await Quotes.api.createProposalWithQuotes(newProposal)
    await store.dispatch(ACTION_SET_SALE_JOURNEY_STATE_QUOTE_CREATED, true)
    const convertQuote: ConvertQuoteModel = {
      quotes: [proposalResp.data.data.quotes[0].id]
    }
    return convertQuote
  },
  async createPolicy(
    productConfig: string,
    productId: string
  ): Promise<ConvertQuoteResponseModel> {
    const convertQuote: ConvertQuoteModel = await this.createProposalWithQuotes(productConfig, productId)
    return this.convertQuotes(convertQuote)
  },
  async getPolicyBundle(policyBundleId: string): Promise<PolicyBundleModel> {
    const bundleResponse = await Policies.api.getPolicyBundle(policyBundleId)
    return bundleResponse.data.data
  },
  async getGmoFrontendPaymentConfig(
    invoiceId: string,
    redirectCompletionCallbackUrl: string
  ): Promise<{ [key: string]: object }> {
    const gmoFrontendPaymentConfig = await Policies.api.getPaymentProviderConfigFrontend(
      'GMO',
      invoiceId,
      {
        successUrl: Config.gmo.successUrl,
        cancelUrl: Config.gmo.cancelUrl,
        extraContext: {},
        redirectCompletionCallbackUrl: redirectCompletionCallbackUrl,
        redirectCookieTimeoutSecs: Config.gmo.redirectCookieTimeoutSeconds
      }
    )
    return gmoFrontendPaymentConfig.data.data
  },
  /**
   * Loads latest claim definition for given product.
   */
  async getClaimDefinition(productId: string): Promise<ClaimDefinitionModel> {
    if (!cachedClaimDefinitions[productId]) {
      const cpres = await Claims.api.getClaimDefinitionProductsByProductId(
        productId
      )
      const claimCode = cpres.data.data[0].code
      const filter = `code[eq]=${claimCode}`
      const response = await Claims.api.getAllClaimDefinitions(
        0,
        1,
        undefined,
        filter
      )
      cachedClaimDefinitions[productId] = response.data.data[0]
    }
    return cachedClaimDefinitions[productId]
  },
  /**
   * Loads claims for a specific policy
   */
  async getClaimsForPolicy(policyId: string): Promise<ClaimModel[]> {
    const filter = `policyId[eq]=${policyId}`

    return (
      await Claims.api.getAllClaims(undefined, undefined, undefined, filter)
    ).data.data
  },
  /**
   * Loads all repairs, that is claims per device/policy the
   * user has submitted.
   */
  async loadRepairs(): Promise<RepairModel[]> {
    const results: RepairModel[] = []
    // TODO: Need to filter by status
    const claimModels = (await Claims.api.getAllClaims(undefined, undefined, '-sfCreated', undefined)).data.data
    const switchPolicyModels = await this.getPolicies()
    for (const switchPolicyModel of switchPolicyModels) {
      const policyClaims = claimModels.filter(
        (cm) => cm.policyId === switchPolicyModel.policyId
      )
      if (policyClaims.length > 0) {
        // This is filled later on
        const repairModel: RepairModel = {
          device: {
            type: '',
            nickname: ''
          },
          claims: [],
          policyId: switchPolicyModel.policyId,
          policyCoverageStartDate: switchPolicyModel.coverageStartDate,
          policyCoverageEndDate: switchPolicyModel.coverageEndDate
        }
        for (const policyClaim of policyClaims) {
          const claimDefinition = await this.getClaimDefinition(
            switchPolicyModel.productId
          )
          const claimConfig: ClaimConfig = {
            language: Config.locale,
            definition: claimDefinition.definition,
            product: switchPolicyModel._product,
            previousClaims: [],
            serialized: policyClaim.config,
            meta: {
              claimDefinitionId: claimDefinition.id,
              policyId: policyClaim.policyId
            }
          }
          const claim = new Claim(policyClaim.claimNumber, claimConfig)
          // This will happen on first iteration only
          const facts = claim.claimEngine.describeFacts().facts.filter((f) => {
            return ['device.type', 'device.nickname'].includes(f.id)
          })
          if (repairModel.device.type === '') {
            repairModel.device.type = facts.find((f) => f.id === 'device.type')?.currentValue || ''
            repairModel.device.nickname = facts.find((f) => f.id === 'device.nickname')?.currentValue || ''
          }
          // Repair info is static, so facts are retrieved immediately.
          repairModel.claims.push({
            claim: claim,
            status: policyClaim.status,
            facts: createClaimOverlay(
              claim,
              policyClaim.sfCustom
            ).describeFacts().facts
          })
        }
        results.push(repairModel)
      }
    }
    return results
  },

  /**
   * Uploads a file to content management service.
   */
  async uploadFile({
    fileType,
    fileName,
    file,
    contextId
  }: {
    fileType: string;
    fileName: string;
    file: any;
    contextId: string;
    metadata?: any | undefined;
  }): Promise<ContentObjectModel> {
    const timeout = 120000 // two minutes
    return (await keepOnErrorAxios({
      method: 'post',
      url: `${Config.shift.url}/v1/content`,
      headers: {
        contextId,
        filename: fileName,
        payloadType: fileType
      },
      withCredentials: true,
      data: file,
      timeout
    })).data.data
  },
  async removeFile(id: string): Promise<ContentObjectModel> {
    const response = Cms.api.deleteObject(id)
    return (await response).data.data
  },
  async getCMSContentByFactId(id: string): Promise<Photo> {
    const [obj, con] = await Promise.all([
      Cms.api.getObjectContent(id, { responseType: 'blob' }),
      Cms.api.getContentObject(id)
    ])
    const blob = new Blob([obj.data], { type: con.data.data.contentType })
    return {
      contentType: con.data.data.contentType,
      name: con.data.data.fileName,
      base64: URL.createObjectURL(blob),
      blob
    }
  },
  /**
   * Extracts serial numbers from uploaded images
   * @param id Content id
   * @returns Map of exctracted serial numbers
   */
  async ocrWorkflow(id: string): Promise<OCRMap> {
    const model: RunWorkflowProcessRequestModel = {
      context: {
        contentObj: { id }
      },
      workflowProcessKey: 'process-aon-ocr-image',
      outputVariableNames: ['ocrTextMap'],
      runTimeoutMillis: 30000
    }
    return await Workflows.api
      .runWorkflowSync(model, { timeout: 120000 })
      .then((result: any) => {
        return result.data.data.ocrTextMap
      })
  },
  async ensureGuestUser(emailAddress: string): Promise<string> {
    const model: RunWorkflowProcessRequestModel = {
      context: {
        user: { email: emailAddress }
      },
      workflowProcessKey: 'create-user',
      outputVariableNames: ['result'],
      runTimeoutMillis: 30000
    }
    return await Workflows.api
      .runWorkflowSync(model, { timeout: 120000 })
      .then((result: any) => {
        return result.data.data.result
      })
  },
  async checkForInUseEmailAddress(emailAddress: string): Promise<boolean> {
    const model: RunWorkflowProcessRequestModel = {
      context: {
        contentObj: { emailAddress }
      },
      workflowProcessKey: 'check-user-email-address-in-use',
      outputVariableNames: ['duplicateEmailAddress'],
      runTimeoutMillis: 30000
    }
    return await Workflows.api
      .runWorkflowSync(model, { timeout: 120000 })
      .then((result: any) => {
        return result.data.data.duplicateEmailAddress
      })
  },
  async getPolicyHolderDetail(): Promise<PolicyPartyModel | null> {
    return await Policies.api
      .getAllPolicyParties()
      .then((result: any) => {
        const policyHolders: PolicyPartyModel[] = result.data.data
        return policyHolders[0] || null
      })
  },
  /**
   * Changes a plan type for a policy with given id
   */
  async changePlanType(
    policyId: string
  ): Promise<any> {
    const response = await Workflows.api.runWorkflowSync({
      workflowProcessKey: 'change-plan-type',
      outputVariableNames: ['validationErrors'],
      runTimeoutMillis: 10000,
      context: policyId as any
    })
    return response.data.data
  },
  /**
   * Cancels a policy with given id
   */
  async cancelSubscription(
    policyId: string,
    consent: boolean
  ): Promise<void> {
    if (!consent) {
      throw new Error('Customer consent required for cancelling plan')
    }
    await Workflows.api.runWorkflowSync({
      workflowProcessKey: 'cancel-subscription',
      outputVariableNames: [],
      runTimeoutMillis: 10000,
      context: {
        policyId: policyId as unknown as object
      }
    })
  },
  async initSettlement(invoiceId: string, type: string): Promise<InvoiceModel> {
    const invoice = await Policies.api.namedInvoiceSettlementAsync(
      type,
      invoiceId,
      type === 'DUMMY' ? '{"dataKey":""}' : ''
    )
    return invoice.data.data
  },
  async getSettlementStatus(
    invoiceId: string
  ): Promise<InvoiceSettlementStatusResponseModel> {
    const status = await Policies.api.invoiceSettlementStatus(invoiceId)
    return status.data.data
  },
  /**
   * Downloads a PDF policy certificate and returns it as a blob
   */
  async downloadPdfPolicyCertificate(
    policyId: string
  ): Promise<PdfPolicyModel> {
    const contents = (await Cms.api.getContentObjectsByContextId(policyId)).data
      .data
    let pdfContent: ContentObjectModel | undefined
    for (const content of contents) {
      if (
        content.metadata &&
        ((content.metadata.type as unknown) as string) === 'POLICY_CERT'
      ) {
        if (
          !pdfContent ||
          Date.parse(pdfContent.sfCreated) < Date.parse(content.sfCreated)
        ) {
          pdfContent = content
        }
      }
    }
    if (!pdfContent) {
      throw new Error('Policy Certificate not found')
    }
    const type = pdfContent.contentType
    const data = (
      await Cms.api.getObjectContent(pdfContent.id, { responseType: 'blob' })
    ).data
    return {
      blob: new Blob([data], { type }),
      data: data,
      filename: pdfContent.fileName,
      contentType: pdfContent.contentType
    }
  },
  /**
   * Returns true if a user can add a new device to his/hers account.
   */
  async canAddAdditionalDevice(): Promise<boolean> {
    const filter = 'status[in]=IN_FORCE,LAPSING,EXPIRING'
    const now = moment(new Date())

    const policies = (
      await Policies.api.getAllPolicies(undefined, undefined, undefined, filter)
    ).data.data.filter(p => {
      const start = moment(p.coverageStartDate)
      const end = moment(p.coverageEndDate)
      return now.isBetween(start, end)
    })
    const count = policies.length
    return count < Config.maxDevices
  },

  async inactivePolicyExistsForDevice(deviceSerialNumber: string): Promise<boolean> {
    const startOfDay6MonthsAgo = moment(new Date()).subtract(6, 'months').format('yyyy-MM-DDT00:00:00')
    const filter = `status[in]=CANCELED,LAPSED,AUTO_LAPSED,EXPIRED|coverageEndDate[gt]=${startOfDay6MonthsAgo}|sfCustom#bodySerialNumber[eq]=${deviceSerialNumber}`
    const policies = (await Policies.api.getAllPolicies(0, 1, undefined, filter)).data.data

    return policies.length > 0
  },

  async duplicateBodySerialExistsForAccount(deviceSerialNumber: string): Promise<boolean> {
    const filter = `status[in]=IN_FORCE,LAPSING,EXPIRING|sfCustom#bodySerialNumber[eq]=${deviceSerialNumber}`
    const policies = (await Policies.api.getAllPolicies(0, 1, undefined, filter)).data.data

    return policies.length > 0
  },

  /**
   * Generates a default device nickname
   */
  async generateDeviceNickname(prefix: string): Promise<string> {
    const successfulPolicyCount = (await Policies.api.getAllPolicies(
      0, 1000, undefined, 'status[nin]=CREATED,PENDING_PAYMENT,ABANDONED')
    ).data.data.length

    return `${prefix} ${successfulPolicyCount + 1}`
  },
  async checkCampaignCode(planType: string, conditionType: string, campaignCode: string): Promise<string> {
    const model: RunWorkflowProcessRequestModel = {
      context: { planType: planType as any, conditionType: conditionType as any, campaignCode: campaignCode as any },
      workflowProcessKey: 'check-campaign-code-discount',
      outputVariableNames: ['campaignCodeResult'],
      runTimeoutMillis: 30000
    }
    return await Workflows.api
      .runWorkflowSync(model, { timeout: 30000 })
      .then((result) => {
        return result.data.data.campaignCodeResult as unknown as string
      })
  },
  async verifyRecaptchaToken(token: string, hotpId: string): Promise<RecaptchaModel> {
    const model: RunWorkflowProcessRequestModel = {
      context: {
        contentObj: { token },
        hotpId: hotpId as any
      },
      workflowProcessKey: 'verify-recaptcha-token',
      outputVariableNames: ['verificationResult'],
      runTimeoutMillis: 30000
    }
    return await Workflows.api
      .runWorkflowSync(model, { timeout: 30000 })
      .then((result: any) => {
        const verificationResult = result.data.data.verificationResult

        return new RecaptchaModel(verificationResult.valid, verificationResult.invalidReason, verificationResult.score)
      })
  },
  async submitEmailContactEnquiry(enquiryDetails: object): Promise<boolean> {
    const model: RunWorkflowProcessRequestModel = {
      context: {
        requestDetails: enquiryDetails
      },
      workflowProcessKey: 'send-contact-us-emails',
      outputVariableNames: [],
      runTimeoutMillis: 30000
    }
    return await Workflows.api
      .runWorkflowSync(model, { timeout: 30000 })
      .then(() => {
        return true
      })
  },
  async checkOpenTasksByPolicy(policyId: any): Promise<string> {
    const model: RunWorkflowProcessRequestModel = {
      context: { policyId },
      workflowProcessKey: 'check-open-tasks-by-policy',
      outputVariableNames: ['openTaskCheckResult'],
      runTimeoutMillis: 30000
    }
    return await Workflows.api
      .runWorkflowSync(model, { timeout: 30000 })
      .then((result: any) => {
        return result.data.data.openTaskCheckResult
      })
  }
}

export interface OCRMap {
  consoleBatterySerialNum: string;
  consoleSerialNum: string;
  joyconLeftSerialNum: string;
  joyconRightSerialNum: string;
}
